/*INICIO*/

#imgInicio2{

  height: 480px;
  width: 1030px;

}

#imgInicio3{

  height: 460px;
  width: 1010px;

}

/*SmartPhones*/

@media (max-width: 767px){

  #imgInicio3{

    height: 180px;
    width: 350px;
  
  }

  #imgInicio2{

    height: 200px;
    width: 350px;
  
  }

  #imgInicio{

    height: 250px;
    width: 350px;
  }

}

/* Tabletas pequeñas */
@media (min-width: 768px) and (max-width: 1023px){

  #imgInicio3{

    height: 160px;
    width: 330px;
  
  }


  #imgInicio2{

    height: 180px;
    width: 330px;
  
  }

  #imgInicio{

    height: 230px;
    width: 330px;
  }
  
}


/*ABOUT US*/


#imgInicio4{
  height: 450px;
  width: 630px;
}

/*SmartPhones*/

@media (max-width: 767px){

  #imgInicio4{
    height: 180px;
    width: 350px;
  }


}

/* Tabletas pequeñas */
@media (min-width: 768px) and (max-width: 1023px){

  #imgInicio4{
    height: 220px;
    width: 390px;
  }

}



/*PROJECTOS*/


#imgProjectos{
    height: 200px;
    width: 210px;
  }

#web, #gitHub{
    color: white;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    border-color: black;
    
  }

#web{
  background-color: rgb(2, 8, 92);
}

#gitHub{
  background-color: rgb(0, 0, 0);
}


h3, h4, h5{
    color: hsl(0, 0%, 100%);
    text-shadow: 2px 5px 5px black;
    font-family: Arial, Helvetica, sans-serif;
  }

  h3{
    font-size: 30px;
  }

  h4{
    font-size: 20px;
  }

  h5{
    font-size: 15px;
  }

/*CONTACTO*/

.imgContacto{
  width: 660px;
}

/*FORM ENVIADO*/


#imgInicio5{
  height: 450px;
  width: 630px;
}


/*SmartPhones*/

@media (max-width: 767px){

  #imgInicio5{
    height: 180px;
    width: 350px;
  }


}

/* Tabletas pequeñas */
@media (min-width: 768px) and (max-width: 1023px){

  #imgInicio5{
    height: 220px;
    width: 400px;
  }

}




/*BOTON WHA*/


.whatsapp-button {
  position: fixed; /* Hace que el botón sea fijo en la pantalla */
  bottom: 20px; /* Distancia desde la parte inferior */
  right: 20px; /* Distancia desde el borde derecho */
  z-index: 1000; /* Asegura que esté por encima de otros elementos */
}

.whatsapp-icon {
  transition: transform 0.3s ease; /* Agrega una transición suave */
  margin-left: 20px;
}

.whatsapp-icon:hover {
  transform: scale(1.5); 
}


/* Boton Wha Smartphones */

@media (max-width: 767px){

.whatsapp-icon {
  height: 50px;
  width: 50px;
}



}